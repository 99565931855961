body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'MarkWebPro';
  src: 
    local('MarkWebPro'), 
    url(./fonts/MarkWebPro/MWP-SB-Reg.woff) format('woff'),
    url(./fonts/MarkWebPro/MWP-SB-Reg.woff2) format('woff2');
}

@font-face {
  font-family: 'MarkWebProLight';
  src: 
    local('MarkWebProLight'), 
    url(./fonts/MarkWebPro/MWP-SB-Light.woff) format('woff'),
    url(./fonts/MarkWebPro/MWP-SB-Light.woff2) format('woff2');
}
@font-face {
  font-family: 'MarkWebProBold';
  src: 
    local('MarkWebProBold'), 
    url(./fonts/MarkWebPro/MWP-SB-Bold.woff) format('woff'),
    url(./fonts/MarkWebPro/MWP-SB-Bold.woff2) format('woff2');
}
